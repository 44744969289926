"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Project = void 0;
class Project {
    constructor() {
        this.id = 0;
        this.name = '';
        this.teams = [];
    }
}
exports.Project = Project;
