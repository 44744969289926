import React from "react";
import {DataGrid, GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useNavigate} from "react-router-dom";
import ConfirmDialog, {ConfirmationDialogState} from "../../components/сonfirm_dialog";
import {Company} from "../../models/core/company";
import {CompanyApiClient} from "../../api_clients/company_api_client";
import styled from "@emotion/styled";
import {useError} from "../../context/errorContext";

const CompaniesGridContainer = styled.div`
    background-color: white;
`

const CompaniesPage = () => {
    const [companies, setCompanies] = React.useState<Company[]>([]);
    const [confirmState, setConfirmState] = React.useState<ConfirmationDialogState>(new ConfirmationDialogState());
    let navigate = useNavigate();
    let {showError} = useError();

    React.useEffect(() => {
        CompanyApiClient.Instance.Get()
            .then(setCompanies, (error) => {
                showError("Error during get companies list", error);
            });
    }, [])

    const editHandler = (id: number) => {
        navigate("/companies/" + id);
    };

    const deleteConfirm = (company: Company) => {
        let dialogState = new ConfirmationDialogState();

        dialogState = {
            ...dialogState,
            open: true,
            id: company.id,
            text: 'Do you really want to delete "' + company.name + '" company?',
        }

        setConfirmState(dialogState);
    };

    const deleteHandler = (id: number) => {
        setTimeout(() => {
            CompanyApiClient.Instance.Delete(id).then(_ => {
                setCompanies(companies.filter(t => t.id !== id));
            }, (error) => {
                showError("Error during company deleting.")
            });
        }, 500);
    };

    const columns: GridColDef<Company>[] = [
        {field: "id", headerName: "Id", width: 70},
        {field: "name", headerName: "Name", flex: 1},
        {field: "countryCode", headerName: "Country Code", flex: 2},
        {
            field: "actions",
            type: "actions",
            width: 150,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Edit"
                    onClick={() => {
                        editHandler(params.row.id)
                    }}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    label="Delete"
                    onClick={() => {
                        deleteConfirm(params.row)
                    }}
                />,
            ],
        },
    ]

    return <>
        <div style={{margin: "25px 50px"}}>
            <h3 style={{display: "flex", justifyContent: "space-between"}}>
                <div>List of companies</div>
                <Button
                    variant="contained"
                    onClick={() => {
                        navigate("/companies/create")
                    }}
                >
                    Add company
                </Button>
            </h3>
            <CompaniesGridContainer>
                <DataGrid columns={columns}
                          rows={companies}
                          initialState={{
                              pagination: {
                                  paginationModel: {
                                      pageSize: 10,
                                  },
                              },
                          }}
                          pageSizeOptions={[10, 25, 50, 100]}
                          autoHeight/>
            </CompaniesGridContainer>
        </div>
        <ConfirmDialog
            state={confirmState}
            setConfirmState={setConfirmState}
            confirmCallback={deleteHandler}
        ></ConfirmDialog>
    </>
}

export default CompaniesPage;