import './App.css';
import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./pages/Layout";
import CompaniesPage from "./pages/companies/list";
import CompanyEditPage from "./pages/companies/edit";
import CompanyCreatePage from "./pages/companies/create";
import ProjectsPage from './pages/projects/list';
import ProjectsEditPage from "./pages/projects/edit";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/de';
import ProtectedRoute from "./hooks/protected_route";
import {UserProvider} from './context/useAuth';
import {PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {msalConfig} from "./authConfig";
import TeamsPage from "./pages/teams/list";
import UsersPage from "./pages/users/list";
import UserEditPage from "./pages/users/edit";
import LoginPage from "./pages/account/login";
import LogoutPage from "./pages/account/logout";
import ErrorDisplay from "./components/error/error_display";

function App() {
    const msalInstance = new PublicClientApplication(msalConfig);
    return (
        <MsalProvider instance={msalInstance}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                <ErrorDisplay />
                <BrowserRouter>
                    <UserProvider>
                        <Routes>
                            <Route path={"/login"} element={<LoginPage/>}></Route>
                            <Route path={"/logout"} element={<LogoutPage/>}></Route>
                            <Route element={<Layout/>}>
                                <Route index path={"/"}
                                       element={<ProtectedRoute><CompaniesPage/></ProtectedRoute>}></Route>
                                <Route index path={"/companies"}
                                       element={<ProtectedRoute><CompaniesPage/></ProtectedRoute>}></Route>
                                <Route path={"/companies/:id"}
                                       element={<ProtectedRoute><CompanyEditPage/></ProtectedRoute>}></Route>
                                <Route path={"/companies/create"}
                                       element={<ProtectedRoute><CompanyCreatePage/></ProtectedRoute>}></Route>

                                <Route path={"/users"}
                                       element={<ProtectedRoute><UsersPage/></ProtectedRoute>}></Route>
                                <Route path={"/users/:id"}
                                       element={<ProtectedRoute><UserEditPage/></ProtectedRoute>}></Route>
                                <Route path={"/users/create"}
                                       element={<ProtectedRoute><UserEditPage/></ProtectedRoute>}></Route>
                                <Route path={"/projects"}
                                       element={<ProtectedRoute><ProjectsPage/></ProtectedRoute>}></Route>
                                <Route path={"/projects/:id"}
                                       element={<ProtectedRoute><ProjectsEditPage/></ProtectedRoute>}></Route>

                                <Route path={"/teams"}
                                       element={<ProtectedRoute><TeamsPage/></ProtectedRoute>}></Route>
                            </Route>
                        </Routes>
                    </UserProvider>
                </BrowserRouter>
            </LocalizationProvider>
        </MsalProvider>
    );
}

export default App; 