import React from "react";
import {Outlet} from "react-router-dom";
import NavigationBar from "../components/navigation/navigation-bar";
import Footer from "../components/footer";
import styled from "@emotion/styled";

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`

const HeaderContainer = styled.div`

`

const MainContainer = styled.main`
    flex: 1;
`

const Layout = ({children}: any) => {
    return (
        <AppContainer>
            <HeaderContainer>
                <NavigationBar></NavigationBar>
                <Outlet/>
            </HeaderContainer>
            <MainContainer>
                {children}
            </MainContainer>
            <Footer/>
        </AppContainer>
    )
};

export default Layout;