"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortInfo = exports.SortInfoList = void 0;
class SortInfoList {
    constructor() {
        this.toQueryString = () => {
            let queryString = "";
            for (const [index, item] of this.list.entries()) {
                if (index !== 0) {
                    queryString += "&";
                }
                queryString += `sortInfoItems[${index}].propertyName=${item.propertyName}&sortInfoItems[${index}].direction=${item.direction}`;
            }
            return queryString;
        };
        this.list = [];
    }
}
exports.SortInfoList = SortInfoList;
class SortInfo {
    constructor(propertyName, direction) {
        this.propertyName = propertyName;
        this.direction = direction;
    }
}
exports.SortInfo = SortInfo;
