import Button from "@mui/material/Button";
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbar,
    GridColDef, GridFilterModel,
    GridPaginationModel,
    GridRowParams,
    GridSortModel
} from "@mui/x-data-grid";
import ConfirmDialog, {ConfirmationDialogState} from "../../components/сonfirm_dialog";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {SortInfo, SortInfoList} from "aba.common.reactapp/dist/models/sortInfoList";
import {FilterItem, FilterItemList} from "aba.common.reactapp/dist/models/filterItemList";
import {PaginationModel, PaginationResult} from "aba.common.reactapp/dist/models/paginationModel";
import {UserApiClient} from "../../api_clients/user_api_client";
import {User} from "aba.common.reactapp/dist/models/core/user";
import styled from "@emotion/styled";
import {useError} from "../../context/errorContext";

const UsersDataGridContainer = styled.div`
    background-color: white;
`

const UsersPage = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [confirmState, setConfirmState] = React.useState<ConfirmationDialogState>(new ConfirmationDialogState());
    const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
    const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>(new PaginationModel());
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>();
    const [isLoading, setIsLoading] = React.useState<boolean>(true)

    const [rowCountState, setRowCountState] = React.useState<number>(0);

    let navigate = useNavigate();
    let {showError} = useError();

    const updateTable = async (apiPaginationModel?: PaginationModel, sortInfoList?: SortInfoList, filterItemList?: FilterItemList) => {
        setIsLoading(true);

        let personsPaginationModel: PaginationResult<User> = new PaginationResult();
        
        try {

            personsPaginationModel = await UserApiClient.Instance.GetPaged(apiPaginationModel, sortInfoList, filterItemList);

        } catch (error: any) {
            showError(undefined, error);
        }

        setUsers(personsPaginationModel.entities);
        setRowCountState(personsPaginationModel.totalCount);

        setIsLoading(false);
    }

    useEffect(() => {
        const apiPaginationModel = new PaginationModel();

        apiPaginationModel.page = paginationModel.page;
        apiPaginationModel.pageSize = paginationModel.pageSize;

        const apiSortInfoList = new SortInfoList();

        sortModel.forEach((item) => {
            apiSortInfoList.list.push(new SortInfo(item.field, item.sort === 'asc' ? 'asc' : 'desc'));
        })

        const apiFilterItemList = new FilterItemList();

        filterModel?.items.forEach((item) => {
            apiFilterItemList.list.push(new FilterItem(item.field, item.operator, item.value))
        })

        updateTable(apiPaginationModel, apiSortInfoList, apiFilterItemList).catch(console.error);
    }, [paginationModel, sortModel, filterModel])


    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        setSortModel([...sortModel]);
    }, []);

    const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
        setFilterModel(filterModel)
    }, []);

    const editHandler = (id: number) => {
        navigate(`/users/${id}`)
    };

    const goBack = () => {
        navigate(-1);
    }

    const deleteConfirm = (person: User) => {
        let dialogState = new ConfirmationDialogState();

        dialogState = {
            ...dialogState,
            open: true,
            id: person.id,
            text: 'Do you really want to delete "' + person.name + '" user?',
        }

        setConfirmState(dialogState);
    };

    const deleteHandler = (id: number) => {
        setTimeout(() => {
            UserApiClient.Instance.Delete(id).then(_ => {
                setUsers(users.filter(t => t.id !== id));
            }, (error) => {
                showError(undefined, error);
            });
        }, 500);
    };

    const columns: GridColDef<User>[] = [
        {field: "id", headerName: "Id", type: "number", width: 70},
        {field: "name", headerName: "Name", flex: 1},
        {
            field: "actions",
            type: "actions",
            width: 100,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Edit"
                    onClick={() => {
                        editHandler(params.row.id)
                    }}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    label="Delete"
                    onClick={() => {
                        deleteConfirm(params.row)
                    }}
                />,
            ],
        },
    ]

    return <>
        <div style={{margin: "25px 50px"}}>
            <h3 style={{display: "flex", justifyContent: "space-between"}}>
                <div>List of persons</div>
                <div>
                    <Button
                        variant="contained"
                        onClick={() => {
                            navigate("create")
                        }}
                    >
                        Add Person
                    </Button>
                    <Button
                        variant="text"
                        onClick={goBack}>
                        Back
                    </Button>
                </div>
            </h3>
            <UsersDataGridContainer>
                <DataGrid columns={columns}
                          rows={users}
                          initialState={{
                              pagination: {
                                  paginationModel
                              },
                          }}
                          pageSizeOptions={[10, 25, 50, 100]}
                          paginationMode="server"
                          onPaginationModelChange={setPaginationModel}
                          rowCount={rowCountState}
                          sortingMode="server"
                          onSortModelChange={handleSortModelChange}
                          filterMode="server"
                          onFilterModelChange={onFilterChange}
                          loading={isLoading}
                          slots={{toolbar: GridToolbar}}
                          slotProps={{
                              toolbar: {
                                  showQuickFilter: true,
                              },
                          }}
                          autoHeight/>
            </UsersDataGridContainer>

        </div>
        <ConfirmDialog
            state={confirmState}
            setConfirmState={setConfirmState}
            confirmCallback={deleteHandler}
        ></ConfirmDialog>
    </>;
}

export default UsersPage;