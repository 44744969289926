import React from "react";
import {useAuth} from "../../context/useAuth";

const LogoutPage = () => {
    const { logout } = useAuth();

    React.useEffect(() => {
        logout();
    }, [])

    return <></>;
}

export default LogoutPage;