import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ProjectsApiClient} from "../../api_clients/project_api_client";
import styled from "@emotion/styled";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Skeleton,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import {TeamsApiClient} from "../../api_clients/team_api_client";
import {Project} from "aba.common.reactapp/dist/models/core/project";
import {Team} from "aba.common.reactapp/dist/models/core/team";
import {useError} from "../../context/errorContext";

const FormStyle = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 50px auto;
    max-width: 900px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px #ccc;
    background-color: white;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ProjectsEditPage = () => {
    const {id} = useParams<{ id: string }>();
    const [project, setProject] = React.useState<Project>(new Project())
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [teams, setTeams] = React.useState<Team[]>([]);
    let navigate = useNavigate();
    let {showError} = useError();

    const validation = yup.object().shape({
        id: yup.number().default(0),
        name: yup.string().required("Name is required"),
        teams: yup.array<Team>().default([])
    });

    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue
    } = useForm<Project>({resolver: yupResolver(validation) })

    React.useEffect(() => {
        TeamsApiClient.Instance.Get()
            .then(setTeams, (error) => {
                showError(error);
            });
        
        if (id && parseInt(id)) {
            ProjectsApiClient.Instance.GetOneById(parseInt(id))
                .then((project) => {
                    setValue('name', project.name);
                    setProject(project);
                    setLoaded(true);
                }, (error) => {
                    showError(error);
                    setLoaded(true);
                })
        } else {

            let project = new Project();
            setProject(project);

            setLoaded(true);
        }
    }, [id])

    const handleSave = (form: Project) => {
        //TODO: Do this thru form
        form.teams = project.teams;
        let projectsApiClient = ProjectsApiClient.Instance;
        if (id && parseInt(id)) {
            projectsApiClient.Update(parseInt(id), form)
                .then(_ => {
                    navigate(-1);
                });
        } else {
            projectsApiClient.Create(form)
                .then(_ => {
                    navigate(-1);
                });
        }
    };

    const handleChange = (event: SelectChangeEvent<number[]>, project: Project) => {
        const {
            target: {value},
        } = event;

        const selectedTeams = typeof value === 'string' ? project.teams : teams.filter(t => value.indexOf(t.id) > -1);
        
        setProject({...project, teams: selectedTeams});
    }
    
    return (
        <>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-body">
                        <FormStyle onSubmit={handleSubmit(handleSave)}>
                            <h3>Project {id ? "Edit" : "Create"}</h3>
                            {(loaded) ? <>
                                <div>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        variant="outlined"
                                        {...register("name", )}
                                    />
                                    {errors.name && <p className="text-white">{errors.name.message}</p>}
                                </div>

                                <div>
                                    <FormControl sx={{width: '100%'}}>
                                        <InputLabel id="teams-select-label">Teams</InputLabel>
                                        <Select
                                            labelId="teams-select-label"
                                            id="teams-select-checkbox"
                                            multiple
                                            variant="standard"
                                            value={project.teams ? project.teams.map(u => u.id) : []}
                                            onChange={(event) => handleChange(event, project)}
                                            input={<OutlinedInput label="Teams"/>}
                                            renderValue={(selected) => teams.filter(u => selected.indexOf(u.id) > -1).map(u => u.name).join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {teams && teams.map((team) => (
                                                <MenuItem key={team.name} value={team.id}>
                                                    <Checkbox checked={project.teams && project.teams.filter(t => t.id === team.id).length !== 0}/>
                                                    <ListItemText primary={team.name}/>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <ButtonContainer>
                                    <Button variant="contained"
                                            type="submit">
                                        Save
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={() => (navigate(-1))}
                                    >
                                        Cancel
                                    </Button>
                                </ButtonContainer>
                            </> : <>
                                <Skeleton height="100px" variant="rounded" animation="wave"></Skeleton>
                            </>}
                        </FormStyle>
                    </div>
                </div> 
            </div>
        </>
    );
}

export default ProjectsEditPage;