import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Team} from "aba.common.reactapp/dist/models/core/team";
import {TeamsApiClient} from "../../api_clients/team_api_client";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {UserProfile} from "aba.common.reactapp/dist/models/user";
import {useError} from "../../context/errorContext";

type TeamCreateInputProps = {
    onTeamAdded(team: Team): void
}

const TeamCreateInput = ({onTeamAdded}: TeamCreateInputProps) => {
    const [teamCreateModel, setTeamCreateModel] = useState<Team>(new Team());
    let {showError} = useError();
    

    const validation = yup.object<Team>().shape({
        id: yup.number().default(0),
        name: yup.string().required("Name is required"),
        users: yup.array<UserProfile>().required().default([])
    });


    const {
        register,
        handleSubmit,
        reset,
    } = useForm<Team>({resolver: yupResolver<Team>(validation)})

    const addTeam = (team: Team) => {
        if (team.name) {
            TeamsApiClient.Instance.Create(team)
                .then((team) => {
                    setTeamCreateModel(team);
                    onTeamAdded(team);
                    reset();
                }, (error) => {
                    showError(error);
                })
        }
    }

    return <>
        <form onSubmit={handleSubmit(addTeam)}>
            <TextField
                id="name"
                placeholder="Add new team"
                variant="standard"
                {...register("name")}
            />
            <Button
                variant="text"
                type="submit"
            >
                Add
            </Button>
        </form>
    </>;
}

export default TeamCreateInput;