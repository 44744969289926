import {Company} from "../models/core/company";
import {CoreApiClient} from "./base_clients/core_api_client";

export class CompanyApiClient extends CoreApiClient<Company>{ 
    private static _instance: CompanyApiClient
    
    constructor() {
        const url = `/companies`

        super(url);
    }

    public static get Instance(): CompanyApiClient {
        return this._instance || (this._instance = new CompanyApiClient());
    }
}