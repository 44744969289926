import {UserLoginInfo, UserProfileToken} from "aba.common.reactapp/dist/models/user";
import {CoreApiClient} from "./base_clients/core_api_client"; 

export class AuthApiClient extends CoreApiClient<UserProfileToken>{
    private static _instance: AuthApiClient;

    constructor() {
        super("/account");
    } 

    public static get Instance(): AuthApiClient {
        return this._instance || (this._instance = new AuthApiClient());
    }
    
    public Login(user: UserLoginInfo) : Promise<UserProfileToken> {
        return this.SendRequest("post", this.url + "/login", user)
    }
}