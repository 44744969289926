import {UserProfile} from "aba.common.reactapp/dist/models/user";
import { CoreApiClient } from "./base_clients/core_api_client";
import {PaginationModel, PaginationResult} from "aba.common.reactapp/dist/models/paginationModel";
import {SortInfoList} from "aba.common.reactapp/dist/models/sortInfoList";
import {FilterItemList} from "aba.common.reactapp/dist/models/filterItemList";
import {User} from "aba.common.reactapp/dist/models/core/user";

export class UserApiClient extends CoreApiClient<UserProfile>{
    private static _instance: UserApiClient;

    constructor() {
        super("/users");
    }

    public static get Instance(): UserApiClient {
        return this._instance || (this._instance = new UserApiClient());
    }

    public GetCurrentUser() : Promise<UserProfile>{
        return this.SendRequest("get", this.url + "/current");
    }

    public GetPaged( pagingModel?: PaginationModel, sortInfoList?: SortInfoList, filterItemList?: FilterItemList) : Promise<PaginationResult<User>> {
        return this.GetPaginatedResult({
            url: this.url + "/paged", 
            pagingModel: pagingModel,
            sortInfoList: sortInfoList,
            filterItemList: filterItemList
        })
    }
}