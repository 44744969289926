import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText, OutlinedInput,
    Select, SelectChangeEvent, Skeleton
} from "@mui/material";
import {UserProfile} from "aba.common.reactapp/dist/models/user";
import MenuItem from "@mui/material/MenuItem";
import {TeamsApiClient} from "../../api_clients/team_api_client";
import {UserApiClient} from "../../api_clients/user_api_client";
import {TeamUserApiClient} from "../../api_clients/team_user_api_client";
import {Team} from "aba.common.reactapp/dist/models/core/team";
import TeamCreateInput from "../../components/team/create-input";
import {useError} from "../../context/errorContext";

const TeamsContainer = styled.div`
    background-color: white;
    padding: 0 1rem;
    border: 1px solid #C6D2D9;
    
    & > div:not(:last-child){
        border-bottom: 1px solid #C6D2D9;
    }
`

const TeamContainer = styled.div`
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const TeamsPage = () => {
    const [teams, setTeams] = useState<Team[]>([]);
    const [users, setUsers] = useState<UserProfile[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    let {showError} = useError();

    useEffect(() => {
        TeamsApiClient.Instance.Get().then(teams => {
            setTeams(teams);
            
            setLoaded(true);
        }, (error) => {
            showError(undefined, error);
            setLoaded(true);
        });
        
        UserApiClient.Instance.Get()
            .then(setUsers, (error) => {
                showError(undefined, error); 
            });
    }, [showError]);

    const handleChange = (event: SelectChangeEvent<number[]>, team: Team) => {
        const {
            target: {value},
        } = event;
        
        const selectedUsers = typeof value === 'string' ? team.users : users.filter(u => value.indexOf(u.id) > -1);

        const addedUsers = selectedUsers.filter(u => !team.users || team.users.filter(tup => u.id === tup.id).length  === 0);
        
        for (const addedUserKey in addedUsers){
            TeamUserApiClient.GetInstance(team.id).Update(addedUsers[addedUserKey].id, {})
                .then(_ => {}, (error) => {
                    showError(undefined, error);
                });
        }
        
        const removedUsers = team.users && team.users.filter(u => selectedUsers.filter(su => u.id === su.id).length === 0);

        for (const removedUserKey in removedUsers){
            TeamUserApiClient.GetInstance(team.id).Delete(removedUsers[removedUserKey].id, {})
                .then(_ => {}, (error) => {
                    showError(undefined, error);
                });
        }
        
        team.users = selectedUsers;
        
        setTeams(teams.map((t) => {
            return t.id === team.id ? team : t;
        }));
    };

    const handleDelete = (team: Team) => {
        TeamsApiClient.Instance.Delete(team.id)
            .then(_ => {
                setTeams(teams.filter(t => t.id !== team.id));
            }, (error) => {
                showError(undefined, error);
            })
    }

    const onTeamAdded = (team: Team) => {
        setTeams([...teams, team]);
    }

    return <>
        <div style={{margin: "25px 50px"}}>
            <h3 style={{display: "flex", justifyContent: "space-between"}}>
                <div>List of teams</div> 
                <TeamCreateInput onTeamAdded={onTeamAdded}></TeamCreateInput>
            </h3>
            <TeamsContainer>
                {
                    loaded ?
                    (teams.length !== 0 ?
                    teams.map((team) => { 
                    return <TeamContainer key={team.id}>
                        <div style={{width: "30%"}}>{team.name}</div>  
                        <div style={{width: "60%"}}>
                            <FormControl sx={{width: '100%'}}>
                                <InputLabel id="users-select-label">Users</InputLabel>
                                <Select
                                    labelId="users-select-label"
                                    id="users-select"
                                    multiple
                                    variant="standard"
                                    value={team.users ? team.users.map(u => u.id) : []}
                                    onChange={(event) => handleChange(event, team)}
                                    input={<OutlinedInput label="Users"/>}
                                    renderValue={(selected) => users.filter(u => selected.indexOf(u.id) > -1).map(u => u.fullName).join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {users && users.map((user) => (
                                        <MenuItem key={user.fullName} value={user.id}>
                                            <Checkbox checked={team.users && team.users.filter(t => t.id === user.id).length !== 0}/>
                                            <ListItemText primary={user.fullName}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <IconButton aria-label="delete" onClick={() => handleDelete(team)}>
                                <DeleteIcon fontSize="small"/>
                            </IconButton>
                        </div>
                    </TeamContainer>
                }) : <>No teams exists</>) : <><Skeleton height={"100px"}/></>}
            </TeamsContainer>
        </div>
    </>;
}

export default TeamsPage;