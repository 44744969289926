import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import styled from "@emotion/styled";
import UserEditForm from "../../components/person/editForm";
import dayjs from "dayjs";
import {UserApiClient} from "../../api_clients/user_api_client";
import {User} from "aba.common.reactapp/dist/models/core/user";
import {Person} from "aba.common.reactapp/dist/models/core/person";
import {useError} from "../../context/errorContext";

const FormStyle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 50px auto;
    max-width: 600px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px #ccc;
    background-color: white;
`;
const UserEditPage = () => {
    const {id} = useParams<{ id: string }>();
    const [user, setUser] = React.useState<User>(new User())
    const [loaded, setLoaded] = React.useState<boolean>(false);
    let navigate = useNavigate();
    const redirectUrl = `/users`;
    let {showError} = useError();

    React.useEffect(() => {
        if (id) {
            UserApiClient.Instance.GetOneById(parseInt(id))
                .then((user) => {
                    setUser(user);
                    setLoaded(true);
                }, (error) => {
                    showError(undefined, error);
                    setLoaded(true);
                })
        } else {
            let user = new User();
            user.person = new Person();
            setUser(user);
            setLoaded(true);
        }
    }, [id])

    const savePerson = (user: User) => {
        if (user.person) {
            user.person.birthday = dayjs(user.person.birthday).format("YYYY-MM-DD")
        }
        if (id) {
            UserApiClient.Instance.Update(parseInt(id), user).then(() => {
                navigate(redirectUrl)
            }, (error) => {
                showError(undefined, error); 
            })
        } else {
            UserApiClient.Instance.Create(user).then(() => {
                navigate(redirectUrl)
            }, (error) => {
                showError(undefined, error);
            })
        }
    }

    return <>
        <div className="container-fluid">
            <div className="card">
                <div className="card-body">
                    <FormStyle>
                        <h3>Person {id ? "Edit" : "Create"}</h3>
                        {loaded && <UserEditForm editUser={user} saveUser={savePerson}></UserEditForm>}
                    </FormStyle>
                </div>
            </div>
        </div>
    </>;
}

export default UserEditPage;