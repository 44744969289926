import {CoreApiClient} from "./base_clients/core_api_client";
import {AccessRole} from "aba.common.reactapp/dist/models/rbac/accessRole";

export class AccessRoleApiClient extends  CoreApiClient<AccessRole>{
    private static _instance: AccessRoleApiClient;
    
    constructor() {
        super("/accessroles");
    }

    public static get Instance(): AccessRoleApiClient {
        return this._instance || (this._instance = new AccessRoleApiClient());
    }
}