"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Team = void 0;
class Team {
    constructor() {
        this.id = 0;
        this.name = '';
        this.users = [];
    }
}
exports.Team = Team;
