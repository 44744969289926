import {CoreApiClient} from "./base_clients/core_api_client";

interface NamedClient {
    [key: number]: TeamUserApiClient
}

export class TeamUserApiClient extends CoreApiClient<any>{
    private static _instances: NamedClient = [];

    constructor(teamId: number) {
        const url = `/teams/${teamId}/users`

        super(url);
    }

    public static GetInstance(teamId: number){
        return this._instances[teamId] || (this._instances[teamId] = new TeamUserApiClient(teamId));
    }
}