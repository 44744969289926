"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterItem = exports.FilterItemList = void 0;
class FilterItemList {
    constructor() {
        this.toQueryString = () => {
            let queryString = "";
            for (const [index, item] of this.list.entries()) {
                if (index !== 0) {
                    queryString += "&";
                }
                queryString += `filterItems[${index}].field=${item.field}&filterItems[${index}].operator=${item.operator}&filterItems[${index}].value=${item.value}`;
            }
            return queryString;
        };
        this.list = [];
    }
}
exports.FilterItemList = FilterItemList;
class FilterItem {
    constructor(field, operator, value) {
        this.field = field;
        this.operator = this.convertOperator(operator);
        this.value = value;
    }
    convertOperator(operator) {
        switch (operator) {
            case '=': return 'equals';
            case '!=': return 'notEquals';
            case '>': return 'greaterThan';
            case '>=': return 'greaterThanOrEqual';
            case '<': return 'lessThan';
            case '<=': return 'lessThanOrEqual';
            default: return operator;
        }
    }
}
exports.FilterItem = FilterItem;
