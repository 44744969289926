import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React from "react";
import {useNavigate} from "react-router-dom";
import styled from "@emotion/styled";
import {Company} from "../../models/core/company";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

type CompanyEditProps = {
    editCompany: Company,
    saveCompany(company: Company): void
}

const ButtonContainer = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
`;

const FormStyle = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
`

const CompanyEditForm = ({editCompany, saveCompany}: CompanyEditProps) => {
    const [company] = React.useState<Company>(editCompany)
    let navigate = useNavigate();

    const validation = yup.object().shape({
        id: yup.number().default(0),
        name: yup.string().required("Name is required"),
        countryCode: yup.string().required("Name is required")
            .max(3, "Country code should be max 3 letters")
    });

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<Company>({resolver: yupResolver(validation), defaultValues: company})

    const handleSave = (form: Company) => {
        saveCompany(form);
    }
    
    return <>
        <FormStyle onSubmit={handleSubmit(handleSave)}>
            <TextField
                id="name"
                label="Name"
                variant="outlined"
                {...register("name")}
            />
            {errors.name && <p className="text-white">{errors.name.message}</p>}
            <TextField
                id="countryCode"
                label="Country code"
                variant="outlined"
                inputProps={{maxLength: 3}}
                {...register("countryCode")}
            />
            {errors.countryCode && <p className="text-white">{errors.countryCode.message}</p>}
            <ButtonContainer>
            <Button variant="contained"
                    type="submit">
                Save
            </Button>
            <Button
                variant="outlined"
                onClick={() => (navigate(-1))}
            >
                Cancel
            </Button>
        </ButtonContainer></FormStyle>
    </>;
}

export default CompanyEditForm;