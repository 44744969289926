import {useNavigate} from "react-router-dom";
import CompanyEditForm from "../../components/company/editForm";
import React from "react";
import {styled} from "@mui/system";
import {Company} from "../../models/core/company";
import {CompanyApiClient} from "../../api_clients/company_api_client";
import {useError} from "../../context/errorContext";

const FormStyle = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    margin: '50px auto',
    maxWidth: '600px',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 5px #ccc',
    backgroundColor: 'white'
});

const CompanyCreatePage = () => {
    let navigate = useNavigate();
    let company = new Company()
    let {showError} = useError();
    
    const saveCompany = (company: Company) => {
        CompanyApiClient.Instance.Create(company).then(() => {
            navigate("/companies");
        }, (error) => {
            showError(error);
        })
    }

    return <>
        <div className="container-fluid">
            <div className="card">
                <div className="card-body">
                    <FormStyle>
                        <h3>Create company</h3>
                        <CompanyEditForm editCompany={company} saveCompany={saveCompany}></CompanyEditForm>
                    </FormStyle>
                </div>
            </div>
        </div>
    </>
}

export default CompanyCreatePage;