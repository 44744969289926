import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Checkbox, FormControl, InputLabel, ListItemText, OutlinedInput, Select, SelectChangeEvent} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {AccessRoleApiClient} from "../../api_clients/access_role_api_client";
import {User} from "aba.common.reactapp/dist/models/core/user";
import {Person} from "aba.common.reactapp/dist/models/core/person";
import {AccessRole} from "aba.common.reactapp/dist/models/rbac/accessRole";
import {useError} from "../../context/errorContext";

interface UserEditFormProps {
    editUser: User,

    saveUser(user: User): void
}

const UserEditForm = ({editUser, saveUser}: UserEditFormProps) => {
    const [user, setUser] = React.useState(editUser)
    const [roles, setRoles] = React.useState<AccessRole[]>([])
    let navigate = useNavigate();
    let {showError} = useError();

    useEffect(() => {
        AccessRoleApiClient.Instance.Get().then(setRoles, (error) => {
            showError(error);
        });
    }, [])

    const handleTextChange = (key: keyof User, value: string) => {
        setUser({...user, [key]: value});
    }

    const handlePersonTextChange = (key: keyof Person, value: string) => {
        if (user.person)
            setUser({...user, person: {...user.person, [key]: value}})
    }

    const handleChange = (event: SelectChangeEvent<number[]>, role: AccessRole) => {
        const {
            target: {value},
        } = event;

        const selectedRoles = typeof value === 'string' ? user.roles : roles.filter(t => value.indexOf(t.id) > -1);

        setUser({...user, roles: selectedRoles});
    }

    return <>
        <TextField
            value={user.name}
            onChange={(event) => handleTextChange("name", event.target.value)}
            id="userName"
            label="Name"
            variant="outlined"
            disabled={user.id !== 0}
        />
        {user.person &&
            <>
                <TextField
                    value={user.person.firstName}
                    onChange={(event) => handlePersonTextChange("firstName", event.target.value)}
                    id="firstName"
                    label="First Name"
                    variant="outlined"
                />
                <TextField
                    value={user.person.lastName}
                    onChange={(event) => handlePersonTextChange("lastName", event.target.value)}
                    id="lastName"
                    label="Last Name"
                    variant="outlined"
                />
            </>
        }
        <div>
            <FormControl sx={{width: '100%'}}>
                <InputLabel id="teams-select-label">Roles</InputLabel>
                <Select
                    labelId="teams-select-label"
                    id="teams-select-checkbox"
                    multiple
                    variant="standard"
                    value={user.roles ? user.roles.map(u => u.id) : []}
                    onChange={(event) => handleChange(event, user)}
                    input={<OutlinedInput label="Teams"/>}
                    renderValue={(selected) => roles.filter(u => selected.indexOf(u.id) > -1).map(u => u.name).join(', ')}
                >
                    {roles && roles.map((role) => (
                        <MenuItem key={role.name} value={role.id}>
                            <Checkbox
                                checked={user.roles && user.roles.filter(t => t.id === role.id).length !== 0}/>
                            <ListItemText primary={role.name}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>

        <div style={{display: "flex", justifyContent: "end", gap: "1rem"}}>
            <Button variant="contained"
                    onClick={() => saveUser(user)}>
                Save
            </Button>
            <Button
                variant="outlined"
                onClick={() => navigate(-1)}
            >
                Cancel
            </Button>
        </div>
    </>
        ;
}

export default UserEditForm;