"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Person = void 0;
class Person {
    constructor() {
        this.id = 0;
        this.firstName = "";
        this.lastName = "";
    }
}
exports.Person = Person;
