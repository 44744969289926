import {CoreApiClient} from "./base_clients/core_api_client";
import {Project} from "aba.common.reactapp/dist/models/core/project";
 
export class ProjectsApiClient extends CoreApiClient<Project>{
    private static _instance: ProjectsApiClient
    
    constructor() {
        const url = `/projects` 
        
        super(url);
    } 
    
    public static get Instance(){
        return this._instance || (this._instance = new ProjectsApiClient());
    }
}