import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import React from "react";
import Button from "@mui/material/Button";
import {DataGrid, GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import ConfirmDialog, {ConfirmationDialogState} from "../../components/сonfirm_dialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {ProjectsApiClient} from "../../api_clients/project_api_client";
import {Project} from "aba.common.reactapp/dist/models/core/project";
import styled from "@emotion/styled";
import {useError} from "../../context/errorContext";

const ProjectsDataGridContainer = styled.div`
    background-color: white;
`

const ProjectsPage = () => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [confirmState, setConfirmState] = React.useState<ConfirmationDialogState>(new ConfirmationDialogState());
    let navigate = useNavigate();
    let {showError} = useError();

    useEffect(() => {
            ProjectsApiClient.Instance
                .Get()
                .then(setProjects, (error) => {
                    showError(error);
                })
    }, [])

    const editHandler = (id: number) => {
        navigate(`/projects/${id}`)
    };

    const goBack = () => {
        navigate(-1);
    }

    const deleteConfirm = (project: Project) => {
        let dialogState = new ConfirmationDialogState();

        dialogState = {...dialogState,
            open: true,
            id: project.id,
            text: 'Do you really want to delete "' + project.name + '" person?',
        }

        setConfirmState(dialogState);
    };
    
    const deleteHandler = (id: number) => {
        setTimeout(() => {
            ProjectsApiClient.Instance.Delete(id).then();
        }, 500);
    };

    const columns : GridColDef<Project>[] = [
        {field: "id", headerName: "Id", width: 70},
        {field: "name", headerName: "Name", flex: 2},
        {
            field: "actions",
            type: "actions",
            width: 100,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Edit"
                    onClick={() => {
                        editHandler(params.row.id)
                    }}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    label="Delete"
                    onClick={() => {
                        deleteConfirm(params.row)
                    }}
                />,
            ],
        },
    ]
    
    return <>
        <div style={{margin: "25px 50px"}}>
            <h3 style={{display: "flex", justifyContent: "space-between"}}>
                <div>List of projects</div>
                <div>
                    <Button
                        variant="contained"
                        onClick={() => {
                            navigate("create")
                        }}
                    >
                        Add Project
                    </Button>
                    <Button
                        variant="text"
                        onClick={goBack}>
                        Back
                    </Button>
                </div>
            </h3> 
            <ProjectsDataGridContainer>
                <DataGrid columns={columns}
                          rows={projects}
                          initialState={{
                              pagination: {
                                  paginationModel: {
                                      pageSize: 10,
                                  },
                              },
                          }}
                          pageSizeOptions={[10, 25, 50, 100]}
                          autoHeight/>
            </ProjectsDataGridContainer>
        </div>
        <ConfirmDialog
            state={confirmState}
            setConfirmState={setConfirmState}
            confirmCallback={deleteHandler}
        ></ConfirmDialog>
    </>;
}

export default ProjectsPage;