import {AxiosClient} from "aba.common.reactapp/dist/api_clients/base_clients/axios_client";
import axios from "axios";

export class CoreApiClient<T> extends AxiosClient<T>{ 
    protected constructor(url: string) {
        let client = axios.create({
            baseURL: process.env.REACT_APP_CORE_API_URL
        });
        super(client, url);
    }
}