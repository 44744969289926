"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationResult = exports.PaginationModel = void 0;
class PaginationModel {
    constructor() {
        this.toQueryString = () => {
            return `page=${this.page}&pageSize=${this.pageSize}`;
        };
        this.page = 0;
        this.pageSize = 10;
    }
}
exports.PaginationModel = PaginationModel;
class PaginationResult {
    constructor() {
        this.entities = [];
        this.totalCount = 0;
    }
}
exports.PaginationResult = PaginationResult;
