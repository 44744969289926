import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {styled} from '@mui/system';
import CompanyEditForm from "../../components/company/editForm";
import {Company} from "../../models/core/company";
import {CompanyApiClient} from "../../api_clients/company_api_client";
import {useError} from "../../context/errorContext";

const FormStyle = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    margin: '50px auto',
    maxWidth: '600px',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 5px #ccc',
    backgroundColor: 'white'
});

const CompanyEditPage = () => {
    const {id} = useParams<{ id: string }>();
    const [company, setCompany] = React.useState<Company | undefined>(undefined)
    const [loaded, setLoaded] = React.useState(false);
    let navigate = useNavigate();
    let {showError} = useError();

    React.useEffect(() => {
        if (id != null) {
            CompanyApiClient.Instance.GetOneById(parseInt(id))
                .then((company) => {
                    setCompany(company);
                    setLoaded(true);
                }, (error) => {
                    setLoaded(true);
                })
        }
    }, [id])

    const saveCompany = (company: Company) => {
        if (id != null) {
            CompanyApiClient.Instance.Update(parseInt(id), company).then(() => {
                navigate("/companies");
            }, (error) => {
                showError(undefined, error);
            })
        }
    }

    return <>
        <div className="container-fluid">
            <div className="card">
                <div className="card-body">
                    <FormStyle>
                        <h3>Company Edit</h3>
                        {loaded &&
                            (company ? <CompanyEditForm editCompany={company}
                                                        saveCompany={saveCompany}></CompanyEditForm> : <>Company does not exist</>)
                        }
                    </FormStyle>
                </div>
            </div>
        </div>
    </>
}

export default CompanyEditPage;